import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { removeNodeAtPath } from 'react-sortable-tree';
import { url } from "routes"
import { Link } from "react-router-dom"
import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"
import { getpageStructureStatus,getpageStructure,postPageStructure,getPageContent,postPageContent} from "data/pageStructure"
import InputFormElement from "UI/InputFormElement"
import SortTreeRelation from "UI/sortTreeRelation"
import UiLoading from "UI/loading"
import { getMiscellaneous } from "data/miscellaneous"
import { EmptyObject } from "constants/emptypobject"
import CustomeTab from "UI/CustomTab"
import { getpageStructurLink } from "data/pageStructureLink"


const pageStructure = () => {
  const [searchFilter,setSearchFilter] = useState({})
  const [saveData,setSaveData] = useState(false)
  const [allPageLoading,setAllPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton,setAddButton]=useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({status:true})
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [inputType, setInputType] = useState([])
  const [treeData,setTreeData] = useState([])
  const [descriptionType,setdescriptionType] = useState('')
  const [treeLength,setTreeLength] = useState(0)
  const [cardElement, setCardElement] = useState([
    {
      name: 'Different Description Type',
      title: 'Description Types',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getpageStructureStatus,
      getData: getpageStructure,
      getPostData:postPageStructure,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Description Title",
          sort: true,

        },
        {
          dataField: "constname",
          text: "Constant Name",
          sort: true,

        },
        {
          dataField: "descriptiontype",
          text: "File Type",
          sort: true,

        },
        {
          dataField: "mobilegride",
          text: "Mobile Gride",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.mobilegride != undefined && prod.mobilegride != null ? <p>{prod.mobilegride}</p> : <p>No</p>,  
        },
        {
          dataField: "midiumgride",
          text: "Middle Gride",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.midiumgride != undefined && prod.midiumgride != null ? <p>{prod.midiumgride}</p> : <p>No</p>,  
        },
        {
          dataField: "largegride",
          text: "Large Gride",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.largegride != undefined && prod.largegride != null ? <p>{prod.largegride}</p> : <p>No</p>,  
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>Active</p> : <p>In Active</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 0,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Title', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Parameters', name: 'descriptiontype', type: 'consType', value: [{label:'Blank',value:'Blank'},{label:'Image',value:'photo'},{label:'File',value:'file'},{label:'Input',value:'input'},{label:'Description',value:'description'},{label:'Text Area',value:'textarea'},{label:'Add One',value:'addone'},{label:'Add Two',value:'addtwo'},{label:'Add Three',value:'addthree'},{label:'Add Four',value:'addfour'},{label:'Add Five',value:'addfive'}], disabled: '', required: true, gride: 12 },
        { title: 'Mobile Device Gride', name: 'mobilegride', type: 'consType', value:[{label:'1',value:'1'},{label:'2',value:'2'},{label:'3',value:'3'},{label:'4',value:'4'},{label:'5',value:'5'},{label:'6',value:'6'},{label:'7',value:'7'},{label:'8',value:'8'},{label:'9',value:'9'},{label:'10',value:'10'},{label:'11',value:'11'},{label:'12',value:'12'}], disabled: '', required: '', gride: 12 },
        { title: 'Medium Device Gride', name: 'midiumgride', type: 'consType', value:[{label:'1',value:'1'},{label:'2',value:'2'},{label:'3',value:'3'},{label:'4',value:'4'},{label:'5',value:'5'},{label:'6',value:'6'},{label:'7',value:'7'},{label:'8',value:'8'},{label:'9',value:'9'},{label:'10',value:'10'},{label:'11',value:'11'},{label:'12',value:'12'}], disabled: '', required: '', gride: 12 },
        { title: 'Large Device Gride', name: 'largegride', type: 'consType', value:[{label:'1',value:'1'},{label:'2',value:'2'},{label:'3',value:'3'},{label:'4',value:'4'},{label:'5',value:'5'},{label:'6',value:'6'},{label:'7',value:'7'},{label:'8',value:'8'},{label:'9',value:'9'},{label:'10',value:'10'},{label:'11',value:'11'},{label:'12',value:'12'}], disabled: '', required: true, gride: 12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton: true,
     
    },
    {
      name: 'Different Page Design',
      title: 'Page Name',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getpageStructureStatus,
      getData: getpageStructure,
      getPostData:postPageStructure,
      statusList:'All',

      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true, 
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Page Name",
          sort: true,

        },
        {
          dataField: "pagedescriptionlink",
          text: "Description Details",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.mainpagedescriptionlink.length !== 0  ? <Button
            
            color="success"
            onClick={e => mainPageContent(e,prod,1,'Main Page Content')}
          >
          Page Content
          </Button>  : 'No Content',
          
        },
        {
          dataField: "pagedescriptionlink",
          text: "Description Details",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.mainpagedescriptionlink.length !== 0  ? <Button
            
            color="success"
            onClick={e => descriptionRelationShip(e,prod,1,'Main Page Structure','Different Page Relation', prod.mainpagedescriptionlink.length)}
          >
         Main Page Structure
          </Button>  : 'No Content',
          
        },
        {
          dataField: "pagedescriptionlink",
          text: "Description Details",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.pagedescriptionlink.length !== 0  ? <Button
            
            color="success"
            onClick={e => descriptionRelationShip(e,prod,1,'Sub Page Structure','Different Page Relation',prod.pagedescriptionlink.length)}
          >
          Sub Page Structure
          </Button>  : 'No Content',
          
        },
        
        {
          dataField: "event",
          text: "Event",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.event == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "gallery",
          text: "Gallery",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.gallery == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "dynamics",
          text: "Dynamic",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.dynamics == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 1,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 1,prod)}
                  />  
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 1,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'importtant', disabled: '' },
        { title: 'Dynmaic Page', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Type Of Page', name: 'typeofpage', type: 'multiConsType', value: [{label:'Dynamic',value:'Dynamic'},{label:'Blog',value:'Blog'},{label:'Career',value:'Career'},{label:'Information',value:'Information'},{label:'Event',value:'Event'}], disabled: '', required: true, gride: 12 },
        { title: 'Dependancy Product', name: 'productdependency', type: 'multiple', value: [], outputDropDown: 'name', outputData: getpageStructurLink, table: 'Different Description Type', status: "Drop Down", required: true, gride: 4 },
        { title: 'Type Of Page Structure', name: 'typeofpagestructure', type: 'select', value: [], outputDropDown: 'name', outputData: getpageStructure, table: 'Different Page Design', status: true, required: true, gride: 4 },
        { title: 'Dependent Category', name: 'category', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Category', status: true, required: true, gride: 4 },
        { title: 'Page Description', name: 'mainpagedescriptionlink', type: 'multiple', value: [], select: {},outputDropDown:'name', outputData:getpageStructure,table:'Different Description Type',status:true, required: true, gride: 4 },
        { title: 'Sub Page Description', name: 'pagedescriptionlink', type: 'multiple', value: [], select: {},outputDropDown:'name', outputData:getpageStructure,table:'Different Description Type',status:true, required: true, gride: 4 },
        { title: 'Short Description', name: 'shortdescription', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Page Short Description', name: 'description', type: 'description', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Dynamic Page', name: 'dynamics', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'dynamics', value: true }, { title: 'In Active', name: 'dynamics', value: false }] },
        { title: 'Product', name: 'product', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'product', value: true }, { title: 'In Active', name: 'product', value: false }] },
        { title: 'Gallery', name: 'gallery', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'gallery', value: true }, { title: 'In Active', name: 'gallery', value: false }] },
        { title: 'Event', name: 'event', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'event', value: true }, { title: 'In Active', name: 'event', value: false }] },
        { title: 'Description Structure', name: 'descriptions', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'None', name: 'descriptions', value: 'none' }, { title: 'Parent', name: 'descriptions', value: 'parent' },{ title: 'Child', name: 'descriptions', value: 'child' },{ title: 'Both', name: 'descriptions', value: 'Both' }] },
        { title: 'Prefix Id', name: 'prefixid', type: 'text',value:'',disabled:'',required:'' },
        { title: 'Image', multiple: true, name: 'image', type: 'file', value: [],label:"Image",multiple:true, uploadtype: 'importtant' },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading:true,
      addButton: true,
      
    },
 
  
   
  ])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    for(let i=0;i<cardElementStatus.length;i++){
        cardElementStatus[i].getStatusData(cardElementStatus[i].name,cardElementStatus[i].statusList).then((res) => {
        cardElementStatus[i].active = res.active
        cardElementStatus[i].inactive = res.inactive
        cardElementStatus[i].total = res.total
        cardElementStatus[i].isLoading = false
        setCardElement([...cardElementStatus])
      })
    }
    setAllPageLoading(false)
  }, [])
  const changeTable = async (e, type, index,prod) => {
    e.preventDefault()
    setIsLoading(true)
    setIndexValue(index)
    const cardElementList = [...cardElement]
    setAddButton(cardElementList[index].addButton)
    setTitle(cardElementList[index].title)

  if (type == 'Table' || type == true || type == false) {
      setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')
    await cardElement[index].getData(cardElementList[index].name,type == 'Table'? "All" :type).then(result => {
        
        if (result.success) {
          setData(result.data)
        } else {
          setData([])
        }
      })
      setIsLoading(false)
    }else if (type == 'Add') {
    for(let i=0;i<cardElementList[index].inputType.length;i++){
      if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
        cardElementList[index].inputType[i].value=[]
        await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
            for (let k = 0; k < results.data.length; k++) {
           
                    let data = {
                        label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                        value: results.data[k]._id,
                    }
                    cardElementList[index].inputType[i].value.push(data)
                  }
            })
          }
      }
   

      setInputType([...cardElementList[index].inputType])
      setOperationTable('Add')
      const si ={status:true}
      setSingleItem({...si})
      setIsLoading(false)
    }else{
    
      setInputType([...cardElement[index].inputType])
      if(type == 'Edit' || type == 'View' || type == 'Deleted'){
        if(cardElement[index].name === 'Different Page Relation'){
          setSortTreeStatus(true)
        }
        await cardElement[index].getData(cardElement[index].name,prod._id).then(async results=>{
          setSingleItem(results.data)
          for(let i=0;i<cardElementList[index].inputType.length;i++){
        
            if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
              cardElementList[index].inputType[i].value=[]
              await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
                  for (let k = 0; k < results.data.length; k++) {
                 
                          let data = {
                              label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                              value: results.data[k]._id,
                          }
                          cardElementList[index].inputType[i].value.push(data)
                        }
                  })
                }
            }
          setIsLoading(false)
          setInputType([...cardElementList[index].inputType])
          setOperationTable(type)
        })
      }

    }
  }


  const postData = (e, values) => {
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    if(values.name !== undefined ){
      console.log(values.name.replace(/[^a-zA-Z ]/g, " ").replace(/\s{2,}/g,' '))
      values['slug'] =values.name.replace(/[^a-zA-Z ]/g, " ").replace(/\s{2,}/g,' ').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name.replace(/[^a-zA-Z ]/g, "");
    }
    if( values.title !== undefined){
      values['slug'] =values.title.replace(/[^a-zA-Z ]/g, "").replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.title.replace(/[^a-zA-Z ]/g, "");
    }
    
   
   setSaveData(true)
    cardElementList[indexValue].getPostData(EmptyObject(values),cardElementList[indexValue].name, operationsTable).then(result => {
      if (result.success) {
        alert(`Data ${operationsTable} Successfully`)
        setSaveData(false)
        setIsLoading(true)
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        cardElementList[indexValue].getData(cardElementList[indexValue].name, status).then(result => {
          if (result.success) {
            setData(result.data)
            setIsLoading(false)
          } else {
            setData([])
            setIsLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  const descriptionRelationShip=async (e,prod,index,title,type,sl)=>{
    console.log('ljaldf')
    setOperationTable('Tree')
    const cardElementStatus = [...cardElement]
    setIndexValue(index)
    setdescriptionType(title)
    setTreeLength(sl)
    setTitle(type)
    setIsLoading(true)
    await cardElementStatus[index].getData(cardElement[index].name,prod._id).then((result)=>{
      if (result.success) {
        console.log(result.data)

        setSingleItem(result.data)
        if(title === 'Sub Page Structure'){

        
        if(result.data.descriptionrelation.length != 0){
          const treeDatas =[]
          for(let i=0;i<result.data.descriptionrelation.length;i++){
              const d={
                title:result.data.descriptionrelation[i].titles.constname,
                titles:result.data.descriptionrelation[i].titles._id,
                children:[],
                expanded: true,
              }
              if(result.data.descriptionrelation[i].children !== 0){
                for(let j=0;j<result.data.descriptionrelation[i].children.length;j++){
                  d.children.push({
                    title:result.data.descriptionrelation[i].children[j].titles.constname,
                    titles:result.data.descriptionrelation[i].children[j].titles._id,
                    children:[],
                    expanded: true,
                  })
                  if(result.data.descriptionrelation[i].children[j].children !== 0){
                    for(let k=0;k<result.data.descriptionrelation[i].children[j].children.length;k++){
                      d.children[j].children.push({
                        title:result.data.descriptionrelation[i].children[j].children[k].titles.constname,
                        titles:result.data.descriptionrelation[i].children[j].children[k].titles._id,
                        children:[],
                        expanded: true,
                      })
                      if(result.data.descriptionrelation[i].children[j].children[k].children !== 0){
                        for(let l=0;l<result.data.descriptionrelation[i].children[j].children[k].children.length;l++){
                          d.children[j].children[k].children.push({
                            title:result.data.descriptionrelation[i].children[j].children[k].children[l].titles.constname,
                            titles:result.data.descriptionrelation[i].children[j].children[k].children[l].titles._id,
                            children:[],
                            expanded: true,
                          })
                          if(result.data.descriptionrelation[i].children[j].children[k].children[l].children !== 0){
                            for(let m=0;m<result.data.descriptionrelation[i].children[j].children[k].children[l].children.length;m++){
                              d.children[j].children[k].children[l].children.push({
                                title:result.data.descriptionrelation[i].children[j].children[k].children[l].children[m].titles.constname,
                                titles:result.data.descriptionrelation[i].children[j].children[k].children[l].children[m].titles._id,
                                children:[],
                                expanded: true,
                              })
                            }
                            
                          }
                        }
                       
                      }
                    }
                  
                  }
                
                }
            
                  
              }
             
              treeDatas.push(d)
          }
          setTreeData(treeDatas)
        
        }
      }else{
        if(result.data.maindescriptionrelation.length != 0){
          const treeDatas =[]
          for(let i=0;i<result.data.maindescriptionrelation.length;i++){
              const d={
                title:result.data.maindescriptionrelation[i].titles.constname,
                titles:result.data.maindescriptionrelation[i].titles._id,
                children:[],
                expanded: true,
              }
              if(result.data.maindescriptionrelation[i].children !== 0){
                for(let j=0;j<result.data.maindescriptionrelation[i].children.length;j++){
                  d.children.push({
                    title:result.data.maindescriptionrelation[i].children[j].titles.constname,
                    titles:result.data.maindescriptionrelation[i].children[j].titles._id,
                    children:[],
                    expanded: true,
                  })
                  if(result.data.maindescriptionrelation[i].children[j].children !== 0){
                    for(let k=0;k<result.data.maindescriptionrelation[i].children[j].children.length;k++){
                      d.children[j].children.push({
                        title:result.data.maindescriptionrelation[i].children[j].children[k].titles.constname,
                        titles:result.data.maindescriptionrelation[i].children[j].children[k].titles._id,
                        children:[],
                        expanded: true,
                      })
                      if(result.data.maindescriptionrelation[i].children[j].children[k].children !== 0){
                        for(let l=0;l<result.data.maindescriptionrelation[i].children[j].children[k].children.length;l++){
                          d.children[j].children[k].children.push({
                            title:result.data.maindescriptionrelation[i].children[j].children[k].children[l].titles.constname,
                            titles:result.data.maindescriptionrelation[i].children[j].children[k].children[l].titles._id,
                            children:[],
                            expanded: true,
                          })
                          console.log(result.data.maindescriptionrelation[i].children[j].children[k].children[l])
                          if(result.data.maindescriptionrelation[i].children[j].children[k].children[l].children !== undefined && result.data.maindescriptionrelation[i].children[j].children[k].children[l].children !== 0){
                            for(let m=0;m<result.data.maindescriptionrelation[i].children[j].children[k].children[l].children.length;m++){
                              d.children[j].children[k].children[l].children.push({
                                title:result.data.maindescriptionrelation[i].children[j].children[k].children[l].children[m].titles.constname,
                                titles:result.data.maindescriptionrelation[i].children[j].children[k].children[l].children[m].titles._id,
                                children:[],
                                expanded: true,
                              })
                            }
                            
                          }
                        }
                       
                      }
                    }
                  
                  }
                
                }
            
                  
              }
             
              treeDatas.push(d)
          }
          setTreeData(treeDatas)
        
        }
      }
        setIsLoading(false)
      } else {
        setData({})
        setIsLoading(false)
      }
    })
  }

  const saveTreeData=()=>{
    const cardElementList = [...cardElement]
    setIsLoading(true)
    cardElementList[indexValue].getPostData( {_id:singleItem._id,treeData:treeData,description:descriptionType},title, 'Edit').then(result => {
      if (result.success) {
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        cardElementList[indexValue].getData(cardElementList[indexValue].name, status).then(result => {
          if (result.success) {
            setData(result.data)
            setIsLoading(false)
          } else {
            setData([])
            setIsLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  const treeDataValue=(tree)=>{
   
    setTreeData([...tree])
  }
  const removeNode =(rowInfo,indexValue)=>{
    const cardElements = [...cardElement]
    let {node, treeIndex, path} = rowInfo;
 
 
    
  const remove_data = removeNodeAtPath({
      treeData: treeData,
      path: path,   // You can use path from here
      getNodeKey: ({node: TreeNode, treeIndex: number}) => {
          // console.log(number);
          return number;
      },
      ignoreCollapsed: false,
   })
   setTreeData(remove_data)

   postPageStructure({
    _id:singleItem._id,
    remove_data:remove_data,
    rowid:rowInfo.node.titles,
    description:descriptionType,
  },'Different Page Relation' ,'Deleted').then(result=>{
    console.log(result)
  })
  }
  const mainPageContent=async(e,prod,index,typeData)=>{
    setIsLoading(true)
    setIndexValue(index)
    await getPageContent(prod._id,'Page').then((result)=>{
      console.log(result)
      if(result.success){
        setData(result.data)
        setSingleItem(result.page)
        setOperationTable(typeData)
        setIsLoading(false)
      }else{
        setData([])
        setSingleItem({})
        setOperationTable(typeData)
        setIsLoading(false)
      }
    })
  }
  const submitFormData=async(e,data,ind)=>{
    e.preventDefault()
    console.log(data)
    setSaveData(true)
    const cardElementList = [...cardElement]
    console.log(ind)
    await postPageContent(data,'Page').then(result=>{
      if(result.success){
        alert("Update Data Successfully")
        setSaveData(false)
     
      
      }
     
    })
  }
  const selctdropDownList=async (e, rowData,indexValue)=>{
    setSearchFilter(e)
    const cardElements = [...cardElement]
    console.log(rowData)
    console.log(indexValue)
    // console.log(cardElements.find(d => d.name === title))
  //  const dataList = new FormData()
  //  dataList.append('datalist',rowData)
  // setSearchType(true)

  // setMessagesLoading(true)
  //   setMessages(`${e.label} Data Loading ...`)    
  // await postMiscellaneous(isEmpty({datalist:rowData}), title, e.label).then(result => {
  //   console.log(result)
  //   if(result.success) {
  //     setMessages(`${e.label} Data Successfully ...`)   
  //     if(e.label == "Export" || e.label == "Sample"){
  //       window.location.href=`${url.commonUrls}/download/${result.data.fileName}`
  //     }else{
  //        setIsLoading(true) 

  //        setColumns([...cardElements.find(d => d.name === title).HeaderColumns])
  //         getMiscellaneous(title, status).then(result => {
  //           if (result.success) {
  //             setSearchFilter({})
  //             setData(result.data)
  //             setOperationTable('Table')
  //             setIsLoading(false)
  //           } else {
  //             setData([])
  //             setOperationTable('Table')
  //             setIsLoading(false)
  //           }
  
  //         })
  //      }
  //     } else {
  //       alert('Hello')
  //     }
  //   })
   
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Page Maker | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Dynamic Pages Dashboard"}
                breadcrumbItem={"Page Maker"}
                bredcumlink={"page-structure"}
              />
              : <Breadcrumbs
                title={'Page Maker'}
                breadcrumbItem={title}
                bredcumlink={'page-structure'}
              />
          }

          <Row>
            {
    
      allPageLoading?
      <UiLoading cardElementOption={!allPageLoading} />
      :
              operationsTable == 'Card' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}> 
                
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type, index)}
                          addButton={d.addButton}
                        />
                  </Col>
                ))
                :
                operationsTable == 'Table' ?
               
                       isLoading ?
                     <UiLoading cardElementOption ={!isLoading ?true :false}/>
                  :
                     <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue)}
                          columns={columns}
                          addButton={addButton}
                          isLoading={isLoading}
                          selctdropDownList={(e,rowData)=>selctdropDownList(e,rowData,indexValue)}
                          searchFilter={searchFilter}
                          selectSearchData={(e,rowData)=>selectSearchData(e,rowData,indexValue)}
                          searchValueLists={(typelist,typedata)=>searchValueLists(typelist,typedata)}
                         />
                    

                
                  :  operationsTable == 'Main Page Content' ?
                  
                    isLoading ?
                     <UiLoading cardElementOption ={!isLoading ?true :false}/>
                  :
                  <CustomeTab  data={data} singleItem={singleItem}  saveData={saveData} submitFormData={(e)=>submitFormData(e,data,indexValue)}/>
                  
                  : 
                  operationsTable == 'Tree' ?
                  
                    isLoading ?
                     <UiLoading cardElementOption ={!isLoading ?true :false}/>
                  :
                  <SortTreeRelation treeLength={treeLength}   isLoading={isLoading} treeData={treeData} saveTreeData={()=>saveTreeData()} treeDataValue={(tree)=>treeDataValue(tree)} removeNode={(rowInfo)=>removeNode(rowInfo,indexValue)}/>
                  
                  :     
                  isLoading ?
                  <UiLoading cardElementOption ={!isLoading ?true :false}/>
               : 
                            <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}
                            bredcumlink={'page-structure'}
                            viewIcon={false}
                            filetype='single'
                            title={title}
                            changeTable={(e, type) => changeTable(e, type, indexValue)}
                            inputType={inputType}
                            postData={postData}
                            isLoading={isLoading}
                            saveData={saveData}
                          />
                          }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default pageStructure
