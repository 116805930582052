import Dashboard from "../pages/Dashboard/index"
import systemadmin from "pages/systemadmin"
import cms from "pages/cms"
import company from "pages/systemadmin/company"
import employee from "pages/systemadmin/employee"
import banner from "pages/cms/banner"
import miscellaneous from "pages/systemadmin/miscellaneous"
import HeaderNavigation from "pages/menumangment/headernavigation"
import FooterNavigation from "pages/menumangment/footernavigation"
import HeaderTree from "pages/menumangment/headerTree"
import FooterTree from "pages/menumangment/footerTree"
import MenuMangmentData from "pages/menumangment"
import DynamicPageStructure from "pages/pageStructure"
import pageStructure from "pages/pageStructure/pageStructure"
import pageStructureLink from "pages/pageStructure/pageStructureLink"
import PageStructureDescription from "pages/pageStructure/pageDescription"
import PublishedLocationList from "pages/pageStructure/publishedArea"
import PromoDashBoard from "pages/Promo"
import LiveNewsDashBoard from "pages/LiveNewsEvent"
import SeoDashboard from "pages/SEO"
import SeoPageStructure from "pages/SEO/AllPageSeo"

import SeoCategoryPageStructure from "pages/SEO/AllPageSeo"
import SeoAllOtherPageStructure from "pages/SEO/AllConstPageSeo"
import BlogPageSeo from "pages/SEO/Blog"
import InformationPageSeo from "pages/SEO/Information"

import Blog from "pages/cms/blog"
import CustomerDashBoard from "pages/Customer"
import BlogReview from "pages/Customer/BlogReview"
import CareerApply from "pages/Customer/CareerApply"
import NewsPageReview from "pages/Customer/NewsPageReview"
import Customer from "pages/Customer/RegisterUser"
import SalesPromoList from "pages/Promo/SalesPromo"
import PollParticipate from "pages/Customer/PollParticipate"
import CareerSeo from "pages/SEO/Career"
import PollSeo from "pages/SEO/Poll"
import EventList from "pages/Event"
import AllGallery from "pages/School/Gallery"
import EventDescription from "pages/EventDescription"
import PageGallery from "pages/PageGallery"
import EventGallery from "pages/EventGallery"
import AllDynamicPages from "pages/SEO/AllDynamicPages"
import AllDynamicEventPages from "pages/SEO/AllDynamicEventPages"
import AllFixPages from "pages/SEO/AllFixPages"
import AllFixPagesList from "pages/SEO/AllFixPagesList"
import SalesDashBoard from "pages/Sales"
import Budget from "pages/Sales/Budget"
import FundCollection from "pages/Sales/Fund-Collection"
import GalleryList from "pages/Gallery"
import ProductList from "pages/Product"
import BlogListLink from "pages/pageStructure/Blog"
import EventListLink from "pages/pageStructure/Event"
import CareerListLink from "pages/pageStructure/Career"
import InformationListLink from "pages/pageStructure/Information"
import DiscountList from "pages/Discount"
import PriceList from "pages/Price"
import categoryStructureLink from "pages/pageStructure/categoryStructureLink"
import CompanyList from "pages/systemadmin/companylist"
import SubPackageList from "pages/subpackage"
import MasterPriceList from "pages/MasterPriceList"
import TypeOfProduction from "pages/pageStructure/typeofproduction"
// const commonUrl = "https://acoscan.bigchimp.in"
//  const commonUrl = "http://localhost:5010"
// const commonUrl = "https://acoscan.bigchimp.in"
const commonUrl = "https://saverstop.ie"
const systemadminMenu = [
  {
    value: "dashboard",
    label: "Home",
  },
  {
    value: "system-admin",
    label: "Dashboard",
  },
  {
    value: "company",
    label: "Company",
  },
 
  {
    value: "company-list",
    label: "Supplier",
  },
  {
    value: "employee",
    label: "Employee",
  },
  {
    value: "miscellaneous",
    label: "Miscellaneous",
  },
]
const cmsMenu = [
  {
    value: "dashboard",
    label: "Dashboard",
  },
  {
    value: "cms-dashboard",
    label: "CMS Dashboard",
  },
  {
    value: "banner",
    label: "Banner",
  },
  {
    value: "add",
    label: "Advertisement",
  },
]
//Custmer
const customerMenu = [
  {
    value: "dashboard",
    label: "Dashboard",
  },
  {
    value: "customer-dashboard",
    label: "Citizen Dashboard",
  },
  {
    value: "customer",
    label: "Customer",
  },
 
  {
    value: "news-review",
    label: "Career",
  },
 
]
const schoolMenu = [
  {
  value: "dashboard",
  label: "Dashboard",
  },
  {
    value: "school-dashboard",
    label: "Event Dashboard",
  },
  {
    value: "all-package",
    label: "Sub Package",
  },
  {
    value: "all-product",
    label: "Product Price",
  },
  // {
  //   value: "all-discount",
  //   label: "Discount",
  // },
  {
    value: "all-price",
    label: "Price Table",
  },
  {
    value: "master-price-table",
    label: "Master Price Table",
  },
  {
    value: "all-gallery",
    label: "Gallery",
  },
  {
    value: "all-event",
    label: "Event",
  },
]
const seoMenu =[
  {
    value:'/dashboard',
    label:'Dashboard'
  },
  {
    value:'/seo-dashboard',
    label:'Seo Dashboard'
  },
  {
    value:'/category-seo',
    label:'Seo'
  },
  {
    value:'/page-seo',
    label:'Dynamic'
  },
  {
    value:'/all-dynamic-seo',
    label:'All Dynamic Page'
  },
  {
    value:'/all-event-seo',
    label:'All Event'
  },
  {
    value:'/all-fix-seo',
    label:'Fix Page'
  },
  {
    value:'/all-fix-page-seo',
    label:'All Fix Page'
  },


]
const menuMangment = [
  {
    value: "dashboard",
    label: "Dashboard",
  },
  {
    value: "menu-management",
    label: "Navigation Dashboard",
  },
  {
    value: "header-navigation",
    label: "Header Navigation",
  },

  {
    value: "header-tree",
    label: "Header Navigation Structure",

  },
  {
    value: "footer-navigation",
    label: "Footer Navigation",

  },
  {
    value: "footer-tree",
    label: "Footer Navigation Structure",

  }
]
const CrmMenu =[
  {
    value:"dashboard",
    label:"Dashboard"
  },
  {
  value:'crm-dashboard',
  label:'Customer Dashboard',
  },
{
  value:'customer',
  label:'Customer'
},

]

const DynamicPageStructureMenu=[
  {
    value: "dashboard",
    label: "Dashboard",
  },
  {
    value: "dynamic-page-structure",
    label: "Dynamic Pages Dashboard",
  },
  // {
  //   value: "published-area",
  //   label: "Publish Area",
  // },
  {
    value: "page-structure",
    label: "Page Maker",
  },
  {
    value: "page-structure-link",
    label: "Page Manager",
  },
  {
    value: "blog-list",
    label: "Blog",
  },
  {
    value: "career-list",
    label: "Career",
  },
  {
    value: "inpormation-list",
    label: "Information",
  },
  {
    value: "event-list",
    label: "Event",
  },
  {
    value: "category-list",
    label: "Category",
  },
]
const promoMenu=[ 
{
  value: "dashboard",
  label: "Dashboard",
}, 
{
  value: "promo-dashboard",
  label: "Adv Dashboard",
},
{
  value: "sales-promo",
  label: "Advertisements",
}, 

]
const salesMenu=[ 
  {
    value: "dashboard",
    label: "Dashboard",
  }, 
  {
    value: "sales",
    label: "Sales Dashboard",
  },
  {
    value: "budget",
    label: "Budget",
  },
  {
    value: "funds-collection",
    label: "Fund",
  },
  // {
  //   value: "sales-promo",
  //   label: "Advertisements",
  // }, 
  
  ]
  const campaignMenu=[ 
    {
      value: "dashboard",
      label: "Dashboard",
    }, 
    {
      value: "campaign",
      label: "Campaign Dashboard",
    },
    {
      value: "email",
      label: "Email",
    },
    {
      value: "whatsapp",
      label: "WhatsApp",
    },
    {
      value: "sms",
      label: "Sms",
    },
    // {
    //   value: "sales-promo",
    //   label: "Advertisements",
    // }, 
    
    ]
    const reportMenu=[ 
      {
        value: "dashboard",
        label: "Dashboard",
      }, 
      {
        value: "campaign",
        label: "Report Dashboard",
      },
      {
        value: "page-annual",
        label: "Dynamic Annual",
      },
      {
        value: "project-annual",
        label: "Project Annual",
      },
      {
        value: "product-annual",
        label: "Product Annual",
      },
     
    ]
const auth=[
  {
    value: "system-admin",
    label: "System",
    component: systemadmin
  },
  {
    value: "dynamic-page-structure",
    label: "Dynamic",
  },
  {
    value: "school-dashboard",
    label: "Product",
  },
  {
    value: "customer",
    label: "Customer",
  },
  {
    value: "sales",
    label: "Sales",
  },
  {
    value: "campaign",
    label: "Campaign",
  },
  {
    value: "menu-management",
    label: "Menu",
  },
  {
    value: "cms",
    label: "CMS",
  },
  // {
  //   value: "customer-dashboard",
  //   label: "Citizen",
  // },
  // {
  //   value: "promo-dashboard",
  //   label: "Adv",
  // },
{
    value: "seo-dashboard",
    label: "SEO",
  },
  {
    value: "report",
    label: "Report",
  },
  {
    value: "Mis.",
    label: "Mis.",
  },
]
const authProtectedRoutes = [
  {
    value: "dashboard", component: Dashboard, 
    menu: [
      {
        value: "system-admin",
        label: "System",
        component: systemadmin
      },
      {
        value: "dynamic-page-structure",
        label: "Dynamic",
      },
      {
        value: "school-dashboard",
        label: "Product",
      },
      {
        value: "customer",
        label: "Customer",
      },
      {
        value: "sales",
        label: "Order",
      },
   
      {
        value: "menu-management",
        label: "Menu",
      },
      {
        value: "cms",
        label: "CMS",
      },
     
    {
        value: "seo-dashboard",
        label: "SEO",
      },
     
     
    ],
  },
  //System Admin
  { value: "system-admin", component: systemadmin, menu: systemadminMenu },
  { value: "company", component: company, menu: systemadminMenu },
  { value: "company-list", component: CompanyList, menu: systemadminMenu },
  { value: "employee", component: employee, menu: systemadminMenu },
  { value: "miscellaneous", component: miscellaneous, menu: systemadminMenu },
  { value: "type-of-production", component: TypeOfProduction, menu: systemadminMenu },

  //Dynamic Section
  { value: "dynamic-page-structure", component: DynamicPageStructure, menu: DynamicPageStructureMenu },
  { value: "page-structure", component: pageStructure, menu: DynamicPageStructureMenu },
   { value: "page-structure-link", component: pageStructureLink, menu: DynamicPageStructureMenu },
   { value: "inpormation-list", component: InformationListLink, menu: DynamicPageStructureMenu },
   { value: "career-list", component: CareerListLink, menu: DynamicPageStructureMenu },
   { value: "event-list", component: EventListLink, menu: DynamicPageStructureMenu },
   { value: "blog-list", component: BlogListLink, menu: DynamicPageStructureMenu },
  //  { value: "blog-list", component: Blo, menu: DynamicPageStructureMenu },
  { value: "page-description", component: PageStructureDescription, menu: DynamicPageStructureMenu },
  { value: "published-area", component: PublishedLocationList, menu: DynamicPageStructureMenu },
  { value: "category-list", component: categoryStructureLink, menu: DynamicPageStructureMenu },

 //Promo
  { value: "promo-dashboard", component:PromoDashBoard, menu: promoMenu },
  { value: "promo-dashboard", component:PromoDashBoard, menu: promoMenu },
  { value: "sales-promo", component:SalesPromoList, menu: promoMenu },
  //CMS
  { value: "cms", component: cms, menu: cmsMenu },
  { value: "banner", component: banner, menu: cmsMenu },
  { value: "cms-page", component: Blog, menu: cmsMenu },
    //CRM
    { value: "customer", component: Customer, menu: auth },
    { value: "crm-dashboard", component: CustomerDashBoard, menu: CrmMenu },
    { value: "contact", component: cms, menu: CrmMenu },
    { value: "contact", component: cms, menu: CrmMenu },
    { value: "banner", component: banner, menu: CrmMenu },
    { value: "cms-page", component: Blog, menu: CrmMenu },
  //Campaign
  { value: "campaign", component: cms, menu: campaignMenu },
  { value: "banner", component: banner, menu: campaignMenu },
  { value: "cms-page", component: Blog, menu: campaignMenu },
  //News
  { value: "school-dashboard", component: LiveNewsDashBoard, menu: schoolMenu },
  // { value: "cabinate", component: Cabinate, menu: schoolMenu },
  { value: "all-event", component: EventList, menu: schoolMenu },
  { value: "all-gallery", component: GalleryList, menu: schoolMenu },
  { value: "all-discount", component: DiscountList, menu: schoolMenu },
  { value: "all-product", component: ProductList, menu: schoolMenu },
  { value: "all-package", component: SubPackageList, menu: schoolMenu },
  { value: "all-price", component: PriceList, menu: schoolMenu },
  { value: "master-price-table", component: MasterPriceList, menu: schoolMenu },
  { value: "page-gallery", component: PageGallery, menu: schoolMenu },

  { value: "event-gallery", component: EventGallery, menu: schoolMenu },
  { value: "event-description", component: EventDescription, menu: schoolMenu },
 
  // { value: "customer-dashboard", component: CustomerDashBoard, menu: customerMenu },
  // { value: "customer-dashboard", component: CustomerDashBoard, menu: customerMenu },
  // { value: "customer", component: Customer, menu: customerMenu },
  // { value: "poll-participate", component: PollParticipate, menu: customerMenu },
  
  // { value: "blog-review", component: BlogReview, menu: customerMenu },
  // { value: "news-review", component: NewsPageReview, menu: customerMenu },
  // { value: "career-apply", component: CareerApply, menu: customerMenu },
  //Menu Mangment
  { value: "menu-management", component: MenuMangmentData, menu: menuMangment },
  { value: "header-navigation", component: HeaderNavigation, menu: menuMangment },

  { value: "footer-navigation", component: FooterNavigation, menu: menuMangment },
  { value: "footer-tree", component: FooterTree, menu: menuMangment },
  { value: "header-tree", component: HeaderTree, menu: menuMangment },
  //Sales
  { value: "sales", component: SalesDashBoard, menu: salesMenu },
  { value: "budget", component: Budget, menu: salesMenu },
  { value: "funds-collection", component: FundCollection, menu: salesMenu },
    //Sales
    { value: "report", component: SalesDashBoard, menu: reportMenu },
    { value: "budget", component: Budget, menu: reportMenu },
    { value: "funds-collection", component: FundCollection, menu: reportMenu },
  //Seo
  { value: "seo-dashboard", component: SeoDashboard, menu: seoMenu },
  { value: "category-seo", component: SeoCategoryPageStructure, menu: seoMenu },
  { value: "page-seo", component: SeoPageStructure, menu: seoMenu },
  { value: "all-dynamic-seo", component: AllDynamicPages, menu: seoMenu },
  { value: "all-event-seo", component: AllDynamicEventPages, menu: seoMenu },
  { value: "all-fix-seo", component: AllFixPages, menu: seoMenu },
  { value: "all-fix-page-seo", component: AllFixPagesList, menu: seoMenu },
]

const url = {
  label: "",
  pagination: 2,
  commonUrls: `${commonUrl}`,
  //systemAdmin
  authentication: `${commonUrl}/api/v1/authentication`,
  company: `${commonUrl}/api/v1/company`,
  supplier: `${commonUrl}/api/v1/supplier`,
  employee: `${commonUrl}/api/v1/employee`,
  policymaster: `${commonUrl}/api/v1/policymaster`,
  miscellaneous: `${commonUrl}/api/v1/miscellaneous`,
  //Page
  page: `${commonUrl}/api/v1/page`,
  pageLink: `${commonUrl}/api/v1/page-link`,
  pageLocation: `${commonUrl}/api/v1/page-location`,
  pageDescription: `${commonUrl}/api/v1/page-description`,
  lineNews: `${commonUrl}/api/v1/live-news`,
  //Customer
  customer: `${commonUrl}/api/v1/customer`,
  newsreview: `${commonUrl}/api/v1/news-review`,
  blogreview: `${commonUrl}/api/v1/blog-review`,
  careerapply: `${commonUrl}/api/v1/career-apply`,
  pollparticipate: `${commonUrl}/api/v1/poll-participate`,
  //Sales Promo
  salespromo: `${commonUrl}/api/v1/sales-promo`,
  //cms
  banner: `${commonUrl}/api/v1/banner`,
  informationpage: `${commonUrl}/api/v1/informationpage`,
  blog: `${commonUrl}/api/v1/blog`,
  //marketing
  marketing: `${commonUrl}/api/v1/marketing`,
  analytics: `${commonUrl}/api/v1/analytics`,

  //Advon
  dynamicmenu: `${commonUrl}/api/v1/dynamicmenu`,
  service: `${commonUrl}/api/v1/service`,
  //School
  event :`${commonUrl}/api/v1/event`,
  discount :`${commonUrl}/api/v1/discount`,
  eventgallery :`${commonUrl}/api/v1/event-gallery`,
  eventgallerydescription :`${commonUrl}/api/v1/event-gallery-description`,
  teacher :`${commonUrl}/api/v1/teacher`,
  student :`${commonUrl}/api/v1/student`,
  cabinate :`${commonUrl}/api/v1/cabinate`,
  result :`${commonUrl}/api/v1/result`,
  notice :`${commonUrl}/api/v1/notice`,
  eventdescription :`${commonUrl}/api/v1/event-description`,
  //Menu Mangmnet 
  menumanagment: `${commonUrl}/api/v1/menumanagment`,
  menutree: `${commonUrl}/api/v1/menutree`,
  footermenumanagment: `${commonUrl}/api/v1/footermenumanagment`,
  footertree: `${commonUrl}/api/v1/footertree`,
  fileUpload: `${commonUrl}/api/v1/fileupload`,
  //SEO Page
  AllPageSeo: `${commonUrl}/api/v1/all-seo-page`,
  AllDynamicPages: `${commonUrl}/api/v1/all-dynamic-page`,
  alldynamicpageevent: `${commonUrl}/api/v1/all-dynamic-event-page`,
  allfixpage: `${commonUrl}/api/v1/all-fix-seo`,
  allfixpagelist: `${commonUrl}/api/v1/all-fix-page-seo`,
  AllConstPageSeo: `${commonUrl}/api/v1/all-constant-page`,
  informationseo: `${commonUrl}/api/v1/information-seo`,
  blogseo: `${commonUrl}/api/v1/blog-seo`,
  price: `${commonUrl}/api/v1/price`,
  masterpricetable: `${commonUrl}/api/v1/master-price-table`,
}

export { authProtectedRoutes, url }
