import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { url } from "routes"
import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"
import { getEvent,getEventStatus,postEvent,getPageContent,getPageGallery,postPageContent,postPageGallery} from "data/event"
import { getpageStructureLinkStatus,getpageStructurLink,postPageStructureLink} from "data/pageStructureLink"
import { getpageStructure } from "data/pageStructure"
import MultiRowTable from "UI/Multirow/MultiRowTable"
import InputFormElement from "UI/InputFormElement"
import UiLoading from "UI/loading"
import SubGalleryCardElementWithEvent from "UI/SubGalleryCardElementWithEvent"
// import { getpageStructurLink } from "data/pageStructureLink"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { isEmpty } from "data/middlefunctions"
import { Link } from "react-router-dom"
import { getEmployee } from "data/systemadmin/employee"
import CustomeTab from "UI/CustomTab"
import CustomImageTab from "UI/CustomImageTab"
import { getSupplier } from "data/systemadmin/supplier"
const ProductList = () => {
  var sum1='';
  var sum2='';
  var sum3='';
  var sum4='';
  var sum5='';
  var sum6='';
  const [messageLoading,setMessagesLoading] = useState(false)
  const [messages,setMessages] = useState('')
  const [saveData,setSaveData] = useState(false)
  const [cardData,setSetCardData] = useState({})
  const [parentcardData,setParentSetCardData] = useState({})
  const [page,setPage] = useState()
  const [searchFilter,setSearchFilter] = useState({})
  const [typeofpage,settypeofpage] = useState()
  const [allPageLoading,setAllPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton,setAddButton]=useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({pagedescription:'',status:true})
  const [productStructure,setProductStructure] = useState([])
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [pageDescription,setPageDescription] = useState([{name:'goapla'}])
  const [inputElemtnType,setInputElemtnType] = useState('')
  const [inputType, setInputType] = useState([
    { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    { title: 'dynamicpage', name: 'uploadtype', type: 'hidden', value: 'dynamicpage', disabled: '' },
    { title: 'supplier', name: 'supplier', type: 'hidden', value: 'supplier', disabled: '' },
    { title: 'category', name: 'category', type: 'hidden', value: 'category', disabled: '' },
    { title: 'Parent Category', name: 'categorylist', type: 'text', value: '', disabled: true, required: '', gride: 4},
    { title: 'Supplier Name', name: 'suppliername', type: 'text', value: '', disabled: true, required: '', gride: 4},
    { title: 'Customer Type', name: 'customertype', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Customer Type', status: true, required: true, gride: 4},
    { title: 'Category', name: 'categorytype', type: 'select', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Category', status: true, required: true, gride: 4},
    { title: 'Supply Region', name: 'supplyregion', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous,table: 'Supply Region', status: true, required: true, gride: 4 },
    { title: 'Type Of Meter', name: 'typeofmeter', type: 'select', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Type Of Meter', status: true,required: true, gride: 4 },
    // { title: 'Discount Coupone', name: 'discountcoupone', type: 'select', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Coupone Code', status: true,required: true, gride: 4 },
    { title: 'Day Rate', name: 'dayrate', type: 'text', value: '', disabled: '', required: true, gride: 4},
    { title: 'Night Rate', name: 'nightrate', type: 'text', value: '', disabled: '', required: true, gride: 4},
    { title: 'Peak Rate', name: 'peakrate', type: 'text', value: '', disabled: '', required: true, gride: 4},
    { title: 'Plan Start From', name: 'planvalid', type: 'date', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Plan Expire To', name: 'planexpair', type: 'date', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Dependancy Package', name: 'pagedecategory', type: 'multiple', value: [], outputDropDown: 'name', outputData: getpageStructurLink, table: 'Category List', status: "Drop Down", required: true, gride: 4 },
    { title: 'Price Note', name: 'pricenote', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
    // { title: 'Consumption Unit', name: 'standardunit', type: 'text', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Image', name: 'image', type: 'file', value: [], single: true,label:"Image", uploadtype: 'page' },
    { title: 'Price Type', name: 'pricetype', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Standard', name: 'pricetype', value: 'Standard' }, { title: 'Fixed', name: 'pricetype', value: 'Fixed' }] },
    { title: 'Type Of Update', name: 'typeofupdate', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Date Yes', name: 'typeofupdate', value: true }, { title: 'Date No', name: 'typeofupdate', value: false }] },
    { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] },
    { title: '', name: 'uploadtype', type: 'hidden', value: 'page', disabled: '' }
])
  const [sortTreeStatus,setSortTreeStatus] = useState(false)
  const [cardElement, setCardElement] = useState([])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    await getMiscellaneous('Category','All').then(async result=>{
      const d=[]
      if(result.success ){
        for(let i=0; i < result.data.length;i++){
          if(result.data[i].pricestatus){

            d.push({
                   supplierlist:result.data[i].supplier,
                  _id: result.data[i]._id,
                  name: result.data[i].name,
                  title: result.data[i].name,
                  total:result.data[i].supplier.length,
                  getStatusData: getEventStatus,
                  getData: getEvent,
                  getPostData:postEvent,
                  statusList:'All',
                  isLoading:false,
                  addButton: false,
                })
          }
            }
            
          
            setCardElement(d)
  }
          setAllPageLoading(false)
          setIsLoading(false)
        
    })
   
    
    setIsLoading(false)
  
  }, [])
  const subchangeTable = async (e,prods) => {
    console.log(prods)
    setIsLoading(true)
    setParentSetCardData(prods)
    setTitle(prods.name)
    let d=[]
    setOperationTable('Sub Card')
    await getSupplier('Company List',true).then(async results=>{
    console.log(results)
    for(let i=0; i < results.data.length;i++){
      if(prods.supplierlist.some(d=> d == results.data[i]._id)){

        await getEventStatus(results.data[i]._id, prods._id).then(result=>{
          console.log(result)
          d.push({
            pagelink:results.data[i]._id,
            category:prods._id,
            categoryname:prods.name,
            supplier:results.data[i]._id,
            suppliername:results.data[i].name,
            _id: results.data[i]._id,
            name: results.data[i].name,
            title: `${results.data[i].name}`,
            total:result.total,
            active:result.active,
            inactive:result.inactive,
            getStatusData: getEventStatus,
            getData: getEvent,
            getPostData:postEvent,
            statusList:'All',
            HeaderColumns: [
              {
                dataField: "_id",
                text: "SL. No",
                sort: true,
                // eslint-disable-next-line react/display-name
                formatter: (cell, prod, index) => index + 1,
              },
              
              {
                dataField: "customertype",
                text: "Customer Type",
                sort: true,
                formatter: (cell, prod, index) =>(
                  prod.customertype != undefined &&  prod.customertype != null &&  prod.customertype.length != 0 ? prod.customertype.map(d=> `${d.name},`) :'No Customer'
                ) 
              },
              {
                dataField: "supplyregion",
                text: "Customer Type",
                sort: true,
                formatter: (cell, prod, index) =>(
                  prod.supplyregion != undefined &&  prod.supplyregion != null &&  prod.supplyregion.length != 0 ? prod.supplyregion.map(d=> `${d.name},`) :'No Supply Region'
                ) 
              },
              {
                dataField: "typeofmeter",
                text: "Type Of Meter",
                sort: true,
                formatter: (cell, prod, index) =>(
                  prod.typeofmeter != undefined &&  prod.typeofmeter != null ? prod.typeofmeter.name :'No Supply Region'
                ) 
              },
              
              {
                dataField: "pricetype",
                text: "Price Type",
                sort: true,
              },
              {
                dataField: "consumptiontype",
                text: "Consumption Type",
                sort: true,
                formatter: (cell, prod, index) =>{
                
                  return prod.usage.consumptiontype
                }
              },
              {
                dataField: "dayrate",
                text: "Day Rate",
                sort: true,
              },
              {
                dataField: "nightrate",
                text: "Night Rate",
                sort: true,
              },
              {
                dataField: "peakrate",
                text: "Peak Rate",
                sort: true,
              },
              {
                dataField: "planvalid",
                text: "Plan Valid",
                sort: true,
                formatter: (cell, prod, index) =>{
                
                  return prod.planvalid.split('T')[0]
                }
              },
              {
                dataField: "planexpair",
                text: "Plan Expaired",
                sort: true,
                formatter: (cell, prod, index) =>{
                
                  return prod.planexpair.split('T')[0]
                }
              },
             
              {
                dataField: "standardunit",
                text: "Total Unit",
                sort: true,
                formatter: (cell, prod, index) =>{
                
                  return prod.usage.annualunit
                }
              },
              {
                dataField: "unitrate",
                text: "Total Price Exc.",
                sort: true,
                formatter: (cell, prod, index) =>{
                  sum1 = prod.usage.consumptiontype == 'All Day' ? ((prod.dayrate/100) * (prod.usage.annualunit * (prod.usage.dayconsumption/100))):
                  prod.usage.consumptiontype == 'Night'? ((prod.dayrate/100)* (prod.usage.annualunit * (prod.usage.dayconsumption)/100)) + ((prod.nightrate/100)* (prod.usage.annualunit * (prod.usage.nightconsumption/100)))
                  :  (parseFloat(prod.dayrate/100) * (prod.usage.annualunit * (prod.usage.dayconsumption/100)))+ ( parseFloat(prod.nightrate/100)* prod.usage.annualunit * (prod.usage.nightconsumption/100))+(parseFloat(prod.peakrate/100)* parseFloat(prod.usage.annualunit * (prod.usage.peakconsumption/100)))
                  return parseFloat(sum1).toFixed(4)
                }
              },
              {
                dataField: "standingcharge",
                text: "Total",
                sort: true,
                formatter: (cell, prod, index) =>{
                  sum2 =  parseFloat(prod.standingcharge)
                  return sum2
                }
              },
              {
                dataField: "standingcharge",
                text: "Total Excl.",
                sort: true,
                formatter: (cell, prod, index) =>{
                  sum3 =  parseFloat(parseFloat(sum1)+ parseFloat(sum2)).toFixed(2)
                  return sum3
                }
              },
              {
                dataField: "pos",
                text: "POS",
                sort: true,
                formatter: (cell, prod, index) =>{
                  sum4 =  parseFloat(parseFloat(sum3)* parseFloat(prod.pos/100)).toFixed(2)
                  return sum4
                }
              },
              {
                dataField: "vat",
                text: "VAT",
                sort: true,
                formatter: (cell, prod, index) =>{
                  sum5 =  parseFloat(parseFloat(sum3)* parseFloat(prod.vat/100)).toFixed(2)
                  return sum5
                }
              },
              {
                dataField: "vat",
                text: "Total Amount",
                sort: true,
                formatter: (cell, prod, index) =>{
                  sum6 =  parseFloat(parseFloat(sum3)+  parseFloat(sum4) + parseFloat(sum5)).toFixed(2)
                  return sum6
                }
              },
          
              {
                      dataField: "action",
                      isDummyField: true,
                      text: "Action",
                      // eslint-disable-next-line react/display-name
                      formatter: (cell, prod, index) => (
                        <>
                          <div className="d-flex gap-3">
                            <Link to="#" className="text-success">
                              <i
                                className="mdi mdi-pencil font-size-18"
                                id="edittooltip"
                                onClick={e => changeTable(e, "Edit", i,results.data[i].name, prod,prods)}
                              />
                            </Link>
                            <Link to="#" className="text-danger">
                              <i
                                className="mdi mdi-delete font-size-18"
                                id="deletetooltip"
                                onClick={e => changeTable(e, "Deleted", i,results.data[i].name,  prod,prods)}
                              />
                            </Link>
                            <Link to="#" className="text-danger">
                              <i
                                className="mdi mdi-eye font-size-18"
                                id="deletetooltip"
                                onClick={e => changeTable(e, "View",i,results.data[i].name,  prod,prods)}
                              />
                            </Link>
                          </div>
                        </>
                      ),
                    },
             
             
            ],
    
            isLoading:false,
            addButton: true,
          })
        })
      }
    }
    setCardElement([...d])
    
    setIsLoading(false)
     
    })
    

  
  }
  const changeTable = async (e, type, index, title,prod,prods) => {
    console.log('ram Gopal')
    
    setIsLoading(true)
    e.preventDefault()
    setIndexValue(index)
   
    setParentSetCardData(prods)
    setSetCardData(prod)
    setPage(prod._id)
    settypeofpage(prod.pagelink)
    const inputLists =[...inputType]
    if (type == 'Table' || type == true || type == false) {
     const cardElementList = [...cardElement]
    
     setAddButton(true)
     setTitle(title)
     setSortTreeStatus(prod.title)
  
     if(prod.HeaderColumns != undefined && prod.HeaderColumns != null){
      setColumns(prod.HeaderColumns)
     }else{
      setColumns(cardElementList[index].HeaderColumns)
     }
      
      setOperationTable('Table')
     
    await getEvent(prod.supplier,type == 'Table'? "All" :type,`${prod.category}-${prod.supplier}`).then(result => {
       console.log(result.data)
        if (result.success) {
          setPageDescription(result.description)
          setData(result.data)
          setIsLoading(false)
        } else {
          setData([])
          setIsLoading(false)
        }
      })
     
    
    }else if (type == 'Add') {
      
      setTitle(title)
      for(let i=0;i<inputLists.length;i++){
        if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
          inputLists[i].value=[]
          await inputLists[i].outputData(inputLists[i].table == 'Category List' ?`${prod.supplier}-${inputLists[i].table}`:inputLists[i].table,inputLists[i].status).then(async results => {
            console.log(inputLists[i].filterType)
            if(inputLists[i].filterType){
              console.log(results.data)
              for (let k = 0; k < results.data.length; k++) {
                let filetData =[]
                await inputLists[i].filterData('',prod.pagelink).then(result=>{
                  console.log(result)
                  if(result.success){
                    filetData=result.data[inputLists[i].filterList] != undefined && result.data[inputLists[i].filterList] != null ?result.data[inputLists[i].filterList] :[] 
                  }else{
                    filetData=[]
                  }
                
                })
                console.log(filetData.includes(results.data[k]._id))
                if(filetData.includes(results.data[k]._id)){
                  let data = {
                      label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                      value: results.data[k]._id,
                  }
                  inputLists[i].value.push(data)
                }
              }
            }else{
              for (let k = 0; k < results.data.length; k++) {
             
                let data = {
                    label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                    value: results.data[k]._id,
                }
                inputLists[i].value.push(data)
              }
            }
              
              })
                
           
            }
        }
        // singleItem.page=prod._id
        // singleItem.typeofpage=prod.pagelink
        // singleItem.status =true
        // console.log(singleItem)
        console.log(prod)
        if(prods.name != undefined && prods.name != null){
          prod.categorylist =prod.name
         }else{
          prod.categorylist=cardElementList[index].name
         }
        prod.categorylist=prods.name
        setSingleItem({...prod})
        setOperationTable('Add')
        setInputType([...inputLists])
        setIsLoading(false)   
    }else{
      console.log(prods)
      setTitle(title)
      setInputType([...inputLists])
      if(type == 'Edit' || type == 'View' || type == 'Deleted'){
        await getEvent(prod.name,prod._id).then(async result=>{
          console.log(prod.supplier)
          for(let i=0;i<inputLists.length;i++){
        
            if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
              inputLists[i].value=[]
              await inputLists[i].outputData( inputLists[i].table == 'Category List' ?`${prod.supplier}-${inputLists[i].table}`:inputLists[i].table,inputLists[i].status).then(async results => {
               console.log(results.data)
                if(inputLists[i].filterType){
                  for (let k = 0; k < results.data.length; k++) {
             
                let filetData =[]
                await inputLists[i].filterData('',prod.pagelink).then(resu=>{
                  console.log(inputLists[i].filterList)
                  console.log(resu.data)
                  if(resu.success){
                    filetData=resu.data[inputLists[i].filterList] != undefined && resu.data[inputLists[i].filterList] != null ?resu.data[inputLists[i].filterList] :[] 
                  }else{
                    filetData=[]
                  }
                
                })
                console.log(filetData)
                console.log(filetData.includes(results.data[k]._id) !== undefined)
                if( filetData.includes(results.data[k]._id)){
                  let data = {
                      label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                      value: results.data[k]._id,
                  }
                  inputLists[i].value.push(data)
                }
              }
            }else{
              console.log(results.data)
              for (let k = 0; k < results.data.length; k++) {
             
                let data = {
                    label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                    value: results.data[k]._id,
                }
                inputLists[i].value.push(data)
              }
            }
                  })
                  console.log(title)
                  result.data.categorylist=prods.name
                  result.data.suppliername=title
                  setSingleItem(result.data)
                  setInputType([...inputLists])
                  setOperationTable(type)
                 
                }else{
                  setSingleItem(result.data)
                  setInputType([...inputLists])
                  setOperationTable(type)
                  
                }
            }
        
   
        })
      }
      setIsLoading(false)
    }
  }
  

  const postData = (e, values) => {
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    
    if(values.name !== undefined) {
      values['slug'] = values.name.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name;
    }
    
    setSaveData(true)
    postEvent(values,'Category',operationsTable).then(async result => {
     if(result.success) {
        setIsLoading(true)
        setSaveData(false)
        alert(`Data ${operationsTable} Successfully`)
      await  getEvent(result.data.supplier,status,`${result.data.category}-${result.data.supplier}`).then(results => {
          console.log(results)
            if (results.success) {
              setColumns(cardElementList[indexValue].HeaderColumns)
              setData(results.data)
              setOperationTable('Table')
              setIsLoading(false)
            } else {
              setData([])
              setIsLoading(false)
            }
          })
         
      } else {
        console.log(result.message)
        alert(result.message)
        setSaveData(false)
      }
    })
  }
  const pageDescriptionList=async(e,id,index,typeData,name)=>{
    setIsLoading(true)
    setTitle(name)
    await getPageContent(id).then((result)=>{
     
      if(result.success){
        setData(result.data)
        setSingleItem(result.page)
        setOperationTable('Page Description')
        setIsLoading(false)
      }else{
        setData([])
        setSingleItem({})
        setOperationTable('Page Description')
        setIsLoading(false)
      }
    })
  }
  const submitFormData=async(e,data)=>{
    e.preventDefault()
    console.log(data)
    setSaveData(true)
    await postPageContent(data).then(result=>{
      console.log(result)
      alert("Data Update Successfully")
      setSaveData(false)
      window.location.href='/all-product'
    })
  }
  const pageGalleryList=async(e,id,index,typeData)=>{
    console.log("demo")
    setIsLoading(true)
    await getPageGallery(id).then((result)=>{
      console.log(result)
      if(result.success){
        setData(result.data)
        setSingleItem(result.single)
        setOperationTable("Gallery")
        setIsLoading(false)
      }else{
        setData([])
        setSingleItem({})
        setOperationTable("Gallery")
        setIsLoading(false)
      }
    })
  }
  const submitImageData=async(e,data)=>{
    console.log("hello")
    await postPageGallery(data).then(result=>{
      
    })
  }
  const selctdropDownList=async (e, rowData,indexValue)=>{
    setSearchFilter(e)
    const cardElements = [...cardElement]
    // setSearchType(true)
    setMessagesLoading(true)
    setMessages(`${e.label} Data Loading ...`)    
  await postEvent(isEmpty({datalist:rowData}), title, e.label).then(result => {
    console.log(result)
    if(result.success) {
      setMessages(`${e.label} Data Successfully ...`)   
      if(e.label == "Export" || e.label == "Sample"){
        window.location.href=`${url.commonUrls}/download/${result.data.fileName}`
      }else{
        location.reload()
        
       }
      } else {
        alert('Hello')
      }
    })
   
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product List | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Dynamic Page Dashboard"}
                breadcrumbItem={"Product List"}
                bredcumlink={"dynamic-page-structure"}
              />
              : <Breadcrumbs
                title={'Product List'}
                breadcrumbItem={title}
                bredcumlink={'all-product'}
              />
          }

          <Row>
            {
              operationsTable == 'Sub Card' ?
              isLoading?
              <UiLoading  />:
              cardElement.map((d, index) => (
                  <Col sm={3} md={3} key={index}> 
                
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type,index,d.name,d,{...parentcardData} )}
                          addButton={true}
                          isLoading={isLoading}
                        />
                  </Col>
                ))
                :
              operationsTable == 'Card' ?
              isLoading?
              <UiLoading/>:
                cardElement.map((d, index) => (
                  <Col sm={3} md={3} key={index}> 
                
                        <SubGalleryCardElementWithEvent
                          data={d}
                          changeTable={(e,) => subchangeTable(e,d)}
                          addButton={d.addButton}
                          isLoading={isLoading}
                        />
                  </Col>
                ))
                :
                operationsTable == 'Gallery' ?
                  
                  isLoading ?
                   <UiLoading />
                :
                <CustomImageTab  data={data} singleItem={singleItem} submitFormData={(e,data)=>submitImageData(e,data)}/>
                
                :operationsTable == 'Page Description' ?
                  
                isLoading ?
                 <UiLoading/>
              :
              <CustomeTab  data={data} singleItem={singleItem} saveData={saveData} submitFormData={(e,data)=>submitFormData(e,data)} title={title}/>
              
              :
                operationsTable == 'Table' ?
                    <>
                     {
                       messageLoading ?  
                        <p style={{textAlign: "center",color: 'green',paddingTop: '16px'}}>{messages}</p>
                       :''
                      }
                      {
                          isLoading ?
                          <UiLoading/>
                       :
                     <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue,title,{...cardData},{...parentcardData})}
                          columns={columns}
                          addButton={true}
                          selctdropDownList={(e,rowData)=>selctdropDownList(e,rowData,indexValue)}
                          searchFilter={searchFilter}
                          selectSearchData={(e,rowData)=>selectSearchData(e,rowData,indexValue)}
                          searchValueLists={(typelist,typedata)=>searchValueLists(typelist,typedata)}
                          isLoading={isLoading}
                          
                        />
                      }
                    </>
                :
              
               isLoading?
          <UiLoading  />
          :
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'InformationPage'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type) => changeTable(e, type, indexValue,title,{...cardData},{...parentcardData})}
                            inputType={inputType}
                            postData={postData}
                            isLoading={isLoading}
                            saveData={saveData}
                            
                          />
                      
               
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}


export default ProductList
