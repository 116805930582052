
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,

} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"


import { getMiscellaneous, getSingleMiscellaneous, postMiscellaneous,getMiscellaneousStatus } from "data/miscellaneous"
import InputFormElement from "UI/InputFormElement"
import { optionsDropDown } from "constants/layout"
import UiLoading from "UI/loading"
import { isEmpty } from "data/middlefunction"
import { getpageStructurLink } from "data/pageStructureLink"
import { getPageContent, getpageStructure, postPageContent } from "data/pageStructure"
import CustomeTab from "UI/CustomTab"
import { getSupplier } from "data/systemadmin/supplier"

const Miscellaneous = () => {
  const [messageLoading,setMessagesLoading] = useState(false)
  const [messages,setMessages] = useState('')
  const [saveData,setSaveData] = useState(false)
  const [searchData,setSearchData] = useState({})
  const [searchType,setSearchType] = useState('')
  const [searchFilter,setSearchFilter] = useState({})
  const [indexValue, setIndexValue] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [operationsTable, setOperationTable] = useState('')
  const [singleItem, setSingleItem] = useState({status:true,currentyear:false})
  const [status, setStatus] = useState(true)
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [inputType, setInputType] = useState([])
  const [cardElement, setCardElement] = useState([
    {
      name: 'Country',
      title: 'Country',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "country",
          text: "Country",
          sort: true,

        },
        {
          dataField: "symboles",
          text: "Symbol",
          sort: true,
          formatter: (cell, prod, index) => prod.symboles !== undefined ? prod.symboles : 'No Symbol'
        },
        {
          dataField: "code",
          text: "Code",
          sort: true,
          formatter: (cell, prod, index) => prod.code !== undefined ? prod.code : 'No Code'
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 0, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Country', name: 'country', type: 'text', value: '', disabled: '', required: true },
        { title: 'Symbol', name: 'symboles', type: 'text', value: '', disabled: '', required: true },
        { title: 'Country Code or ISD Code', name: 'code', type: 'text', value: '', disabled: '', required: true },
        // { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: false },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      
      isLoading: true,
    },
     
    {
      name: 'State',
      title: 'Region',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "country",
          text: "Country",
          sort: true,
          formatter: (cell, prod, index) => prod.country !== undefined && prod.country != null  ? prod.country.country : '',
        },
        {
          dataField: "state",
          text: "State",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 1, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 1, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 1, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Country', name: 'country', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'country', table: 'Country', status: true, disabled: '', required: true, },
        { title: 'State', name: 'state', type: 'textarea', value: '',inputvalue:'text',inputtype:true, disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'City',
      title: 'County',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "country",
          text: "Country",
          sort: true,
          formatter: (cell, prod, index) => prod.state !== undefined &&  prod.state.country !== undefined? prod.state.country.country : '',
        },
        {
          dataField: "country",
          text: "State",
          sort: true,
          formatter: (cell, prod, index) => prod.state !== undefined &&  prod.state !== undefined? prod.state.state : '',
        },
        {
          dataField: "city",
          text: "City",
          sort: true,
        
        },
      
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 2, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 2, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 2, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'State', name: 'state', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'state', table: 'State', status: true, disabled: '', required: true, },
        { title: 'City', name: 'city', type: 'textarea', value: '',inputvalue:'text',inputtype:true,  disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Town',
      title: 'Town',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "country",
          text: "Country",
          sort: true,
          formatter: (cell, prod, index) => prod.city !== undefined &&  prod.city.state !== undefined  &&  prod.city.state.country !== undefined? prod.city.state.country.country : '',
        },
        {
          dataField: "country",
          text: "State",
          sort: true,
          formatter: (cell, prod, index) => prod.city !== undefined &&  prod.city.state !== undefined ? prod.city.state.state : '',
        },
        {
          dataField: "state",
          text: "City",
          sort: true,
          formatter: (cell, prod, index) => prod.city !== undefined ? prod.city.city : '',
        },
        {
          dataField: "city",
          text: "Town",
          sort: true,
          formatter: (cell, prod, index) => prod.town !== undefined ? prod.town : '',
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 3, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 3, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 3, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'City', name: 'city', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'city', table: 'City', status: true, disabled: '', required: true, },
        { title: 'Town', name: 'town', type: 'textarea', value: '',inputvalue:'text',inputtype:true,  disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Area',
      title: 'Area',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "country",
          text: "Country",
          sort: true,
          formatter: (cell, prod, index) => prod.town !== undefined &&  prod.town.city !== undefined &&  prod.town.city.state !== undefined &&  prod.town.city.state.country !== undefined? prod.town.city.state.country.country : '',
        },
        {
          dataField: "country",
          text: "State",
          sort: true,
          formatter: (cell, prod, index) => prod.town !== undefined &&  prod.town.city !== undefined &&  prod.town.city.state !== undefined? prod.town.city.state.state : '',
        },
        {
          dataField: "state",
          text: "City",
          sort: true,
          formatter: (cell, prod, index) => prod.town !== undefined &&  prod.town.city !== undefined? prod.town.city.city : '',
        },
        {
          dataField: "city",
          text: "Town",
          sort: true,
          formatter: (cell, prod, index) => prod.town !== undefined ? prod.town.town : '',
        },
        {
          dataField: "area",
          text: "Area",
          sort: true,

        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 4, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 4, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 4, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Town', name: 'town', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'town', table: 'Town', status: true, disabled: '', required: true, },
        { title: 'Area', name: 'area', type: 'textarea', value: '', disabled: '',inputvalue:'text',inputtype:true, required: '' },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Operational Year',
      title: 'Operational Year',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true, formatter: (cell, prod, index) => index + 1
        },
        {

          dataField: "name",
          text: "Academic Year",
          sort: true
        },
        {
          dataField: "from",
          text: "From",
          sort: true,
          formatter: (cell, prod, inde) => (
            `${prod.from.split('T')[0].split('-')[2]}-${prod.from.split('T')[0].split('-')[1]}-${prod.from.split('T')[0].split('-')[0]}`
          )
        },
        
        {
          dataField: "to",
          text: "To",
          sort: true,
          formatter: (cell, prod, inde) => (
            `${prod.to.split('T')[0].split('-')[2]}-${prod.to.split('T')[0].split('-')[1]}-${prod.to.split('T')[0].split('-')[0]}`
          )
        },
        {

          dataField: "order",
          text: "Order",
          sort: true
        },
        {

          dataField: "currentyear",
          text: "Current Year",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.currentyear == true ? <p>ON</p> : <p>OFF</p>,
          
        },

        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 5, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 5, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 5, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [{ title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '', }, 
      { title: 'Start Date', name: 'from', type: 'date', value: '', disabled: '', required: true }, 
      { title: 'End Date', name: 'to', type: 'date', value: '', disabled: '', required: true }, 
      { title: 'Order Number', name: 'order', type: 'number', value: '', disabled: '', required: true },
      { title: 'Current Year', name: 'currentyear', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'currentyear', value: true }, { title: 'In Active', name: 'currentyear', value: false }] },
      { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }],
      isLoading: true,
    },
    {
      name: 'Category',
      title: 'Category',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "pagedescriptionlink",
          text: "Category",
          sort: true,
          formatter: (cell, prod, index) =>
          <Button
          color="success"
          onClick={e => mainPageContent(e,prod,5,'Main Page Content')}
        >
         Page Content
        </Button>
          
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 6, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted",6, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View",6, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'product', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Dependancy Page', name: 'pagedependency', type: 'select', value: [], outputDropDown: 'name', outputData: getpageStructure, table: 'Different Page Design', status: "All", required: true, gride: 4 },
        { title: 'Dependancy Form', name: 'pagedepenform', type: 'select', value: [], outputDropDown: 'name', outputData: getpageStructure, table: 'Different Page Design', status: "All", required: true, gride: 4 },
        { title: 'Dependency Category', name: 'dependancycategory', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Category', status: true, required: true, gride: 4 },
        { title: 'Child Category', name: 'childcategory', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Category', status: true, required: true, gride: 4 },
        { title: 'Supplier', name: 'supplier', type: 'multiple', value: [], outputDropDown: 'name', outputData: getSupplier, table: 'Company List', status: true, required: true, gride: 4 },
        { title: 'Blog List', name: 'blogdependency', type: 'multiple', value: [], outputDropDown: 'name', outputData: getpageStructurLink, table: 'Different Description Type', status: "Drop Down", required: true, gride: 4 },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: '', gride: 4 },
        { title: 'Customer Category', name: 'customercategory', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Customer Category', status: true, required: true, gride: 4 },
        { title: 'Customer Type', name: 'customertype', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Customer Type', status: true, required: true, gride: 4 },
        { title: 'Supply Region', name: 'supplyregion', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Supply Region', status: true, required: true, gride:3 },
        { title: 'Type Of Meter', name: 'typeofmeter', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Type Of Meter', status: true, required: true, gride: 3 },
        { title: 'Bill Payment', name: 'billpayment', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Bill Payment', status: true, required: true, gride: 3 },
        { title: 'Billing Mode', name: 'billmode', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Billing Mode', status: true, required: true, gride: 3 },
         { title: 'Short Description', name: 'sortdescription', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Form Description', name: 'formdescription', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Image', uploadtype: 'product', },
        { title: 'Banner Image', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'product',},
        { title: 'Description', name: 'description', type: 'textarea', value: '', disabled: '', required: true },
        { title: 'Parent Category', name: 'parentcategorystatus', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'parentcategorystatus', value: true }, { title: 'In Active', name: 'parentcategorystatus', value: false }] },
        { title: 'Price', name: 'pricestatus', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'pricestatus', value: true }, { title: 'In Active', name: 'pricestatus', value: false }] },
        { title: 'Parent Package', name: 'packagestatus', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'packagestatus', value: true }, { title: 'In Active', name: 'packagestatus', value: false }] },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] },
      ],
      isLoading: true,
    },
    {
      name: 'File Upload',
      title: 'File Upload',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Suppler",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.supplier != undefined &&  prod.supplier != null ? <p>{prod.supplier.name}</p> : <p>No Supplier</p>,
        },
        
        {
          dataField: "foldername",
          text: "Folder",
          sort: true,
        },
        {
          dataField: "name",
          text: "Title",
          sort: true,
        },
        
        {
          dataField: "description",
          text: "Note",
          sort: true,
         
        },

        {
          dataField: "url",
          text: "URL",
          sort: true,
          formatter: (cell, prod, index) => (
            prod.file.length != 0 ?
            prod.file.map((dk,ik)=> <Button key={ik} className="btn" onClick={() => openUrlLink(prod.uploadtype,dk)}>{prod.name}-{parseInt(ik + 1)}</Button>)
            
            :'No File'
           
          )
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 7, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 7, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 7, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'importtant', disabled: '' },
        { title: 'Supplier Name', name: 'supplier', type: 'select', value: [], outputDropDown: 'name', outputData: getSupplier, table: 'Company List', status: "All", required: true, gride: 12 },
        { title: 'Folder Name', name: 'foldername', type: 'text', value: '', disabled: '',required: true },
        { title: 'Title', name: 'name', type: 'text', value: '', disabled: '',required: true },
        { title: 'Description', name: 'description', type: 'textarea', value: '', disabled: '',required: true },
        { title: 'Image', multiple: true, name: 'file', type: 'file', value: [],label:"Image",multiple:true, uploadtype: 'importtant' },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    // {
    //   name: 'Multiple File Upload',
    //   title: 'Multiple File Upload',
    //   active: 0,
    //   inactive: 0,
    //   total: 0,
    //   getStatus:getMiscellaneousStatus,
    //   getData:getMiscellaneous,
    //   postData:postMiscellaneous,
    //   HeaderColumns: [
    //     {
    //       dataField: "_id",
    //       text: "SL. No",
    //       sort: true,
    //       // eslint-disable-next-line react/display-name
    //       formatter: (cell, prod, index) => index + 1,
    //     },
    //     {
    //       dataField: "name",
    //       text: "Suppler",
    //       sort: true,
    //       formatter: (cell, prod, index) =>
    //         prod.supplier != undefined &&  prod.supplier != null ? <p>{prod.supplier.name}</p> : <p>No Supplier</p>,
    //     },
        
    //     {
    //       dataField: "foldername",
    //       text: "Folder",
    //       sort: true,
    //     },
    //     {
    //       dataField: "name",
    //       text: "Title",
    //       sort: true,
    //     },
        
    //     {
    //       dataField: "description",
    //       text: "Note",
    //       sort: true,
         
    //     },

    //     {
    //       dataField: "url",
    //       text: "URL",
    //       sort: true,
    //       formatter: (cell, prod, index) => (
    //         prod.file.length != 0 ?
    //         prod.file.map((dk,ik)=> <Button key={ik} className="btn" onClick={() => openUrlLink(prod.uploadtype,dk)}>{prod.name}-{parseInt(ik + 1)}</Button>)
            
    //         :'No File'
           
    //       )
    //     },
    //     {
    //       dataField: "",
    //       text: "Status",
    //       sort: true,
    //       formatter: (cell, prod, index) =>
    //         prod.status == true ? <p>ON</p> : <p>OFF</p>,
    //     },
    //     {
    //       dataField: "action",
    //       isDummyField: true,
    //       text: "Action",
    //       // eslint-disable-next-line react/display-name
    //       formatter: (cell, prod, index) => (
    //         <>
    //           <div className="d-flex gap-3">
    //             <Link to="#" className="text-success">
    //               <i
    //                 className="mdi mdi-pencil font-size-18"
    //                 id="edittooltip"
    //                 onClick={e => changeTable(e, "Edit", 7, prod)}
    //               />
    //             </Link>
    //             <Link to="#" className="text-danger">
    //               <i
    //                 className="mdi mdi-delete font-size-18"
    //                 id="deletetooltip"
    //                 onClick={e => changeTable(e, "Deleted", 7, prod)}
    //               />
    //             </Link>
    //             <Link to="#" className="text-danger">
    //               <i
    //                 className="mdi mdi-eye font-size-18"
    //                 id="deletetooltip"
    //                 onClick={e => changeTable(e, "View", 7, prod)}
    //               />
    //             </Link>
    //           </div>
    //         </>
    //       ),
    //     },
    //   ],
    //   inputType: [
    //     { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    //     { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'importtant', disabled: '' },
    //     { title: 'Supplier Name', name: 'supplier', type: 'select', value: [], outputDropDown: 'name', outputData: getSupplier, table: 'Company List', status: "All", required: true, gride: 12 },
    //     { title: 'Folder Name', name: 'foldername', type: 'text', value: '', disabled: '',required: true },
    //     { title: 'Title', name: 'name', type: 'text', value: '', disabled: '',required: true },
    //     { title: 'Description', name: 'description', type: 'textarea', value: '', disabled: '',required: true },
    //     { title: 'Image', multiple: true, name: 'file', type: 'file', value: [],label:"Image",multiple:true, uploadtype: 'importtant' },
    //     { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
    //   ],
    //   isLoading: true,
    // },
    {
      name: 'Social Link',
      title: 'Social Link',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Social Media",
          sort: true,
        },
        {
          dataField: "image",
          text: "Image",
          sort: true,
          formatter: (cell, prod, index) => (
            <img src={prod.image !== undefined ? `${url.commonUrls}/image/${prod.uploadtype}/${prod.image}` : ''} width='30px' height='30px' />
          )
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 8, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 8, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 8, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: '', name: 'uploadtype', type: 'hidden', value: 'sociallink', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Image', name: 'image', type: 'file', value: [], single: true,label:"Image", uploadtype: 'sociallink' },
        { title: 'Svg Code', name: 'svgcode', type: 'textarea', value: [], single: true, uploadtype: 'sociallink' },
        { title: 'Description', name: 'description', type: 'textarea', value: '', disabled: '', required: true },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Type Of Document',
      title: 'Type Of Document',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "document",
          text: "Name",
          sort: true,
        },
        {
          dataField: "abbriviation",
          text: "Abbriviation",
          sort: true,
        },
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 9, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 9, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 9, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'document', type: 'text', value: '', disabled: '', required: true },
        { title: 'Abbriviation', name: 'abbriviation', type: 'text', value: '', disabled: '', required: '' },
        { title: 'Description', name: 'description', type: 'textarea', value: '', disabled: '', required: '' },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Supply Region',
      title: 'Supply Region',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 10, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 10, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 10, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Type Of Meter',
      title: 'Type Of Meter',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
       
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 11, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 11, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 11, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Supply Region', name: 'region', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Supply Region', status: true, disabled: '', required: true, },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Day Consume(%)', name: 'dayconsumption', type: 'text', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Night Consume(%)', name: 'nightconsumption', type: 'text', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Peak Consume(%)', name: 'peakconsumption', type: 'text', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
  
    {
      name: 'Bill Payment',
      title: 'Bill Payment',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
      
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 12, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 12, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 12, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        // { title: 'Different Per.', name: 'differentper', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Different Per.', status: true, disabled: '', required: true, },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    
  
    {
      name: 'Customer Category',
      title: 'Customer Category',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
       
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 13, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 13, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 13, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Customer Type',
      title: 'Customer Type',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
      
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 14, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 14, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 14, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true },
       
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'POS',
      title: 'POS',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "from",
          text: "Type Of Service",
          sort: true,
          formatter: (cell, prod, inde) => (
            prod.category !== undefined && prod.category !== null ? prod.category.name :''
          )
        },
        {
          dataField: "from",
          text: "Type Of Business",
          sort: true,
          formatter: (cell, prod, inde) => (
            prod.customercategory !== undefined && prod.customercategory !== null ? prod.customercategory.name :''
          )
        },
        {
          dataField: "pos",
          text: "POS",
          sort: true,
        
        },
        {
          dataField: "from",
          text: "Start Date",
          sort: true,
          formatter: (cell, prod, inde) => (
            `${prod.startdate.split('T')[0].split('-')[2]}-${prod.startdate.split('T')[0].split('-')[1]}-${prod.startdate.split('T')[0].split('-')[0]}`
          )
        },
        
        {
          dataField: "to",
          text: "End Date",
          sort: true,
          formatter: (cell, prod, inde) => (
            `${prod.enddate.split('T')[0].split('-')[2]}-${prod.enddate.split('T')[0].split('-')[1]}-${prod.enddate.split('T')[0].split('-')[0]}`
          )
        },
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 15, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 15, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 15, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Type Of Service', name: 'category', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Category', status: true, disabled: '', required: true,gride:4 },
        { title: 'Type Of Business', name: 'customercategory', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Customer Category', status: true, disabled: '', required: true,gride:4 },
        { title: 'Start Date', name: 'startdate', type: 'date', value: '', disabled: '', required: true,gride:4 },
        { title: 'End Date', name: 'enddate', type: 'date', value: '', disabled: '', required: true,gride:4 },
        { title: 'POS', name: 'pos', type: 'text', value: '', disabled: '', required: true ,gride:4},
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true,gride:4 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'VAT',
      title: 'VAT',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
      
        {
          dataField: "from",
          text: "Type Of Service",
          sort: true,
          formatter: (cell, prod, inde) => (
            prod.category !== undefined && prod.category !== null ? prod.category.name :''
          )
        },
        {
          dataField: "from",
          text: "Type Of Business",
          sort: true,
          formatter: (cell, prod, inde) => (
            prod.customercategory !== undefined && prod.customercategory !== null ? prod.customercategory.name :''
          )
        },
        {
          dataField: "vat",
          text: "VAT",
          sort: true,
        
        },
        {
          dataField: "from",
          text: "Start Date",
          sort: true,
          formatter: (cell, prod, inde) => (
            `${prod.startdate.split('T')[0].split('-')[2]}-${prod.startdate.split('T')[0].split('-')[1]}-${prod.startdate.split('T')[0].split('-')[0]}`
          )
        },
        
        {
          dataField: "to",
          text: "End Date",
          sort: true,
          formatter: (cell, prod, inde) => (
            `${prod.enddate.split('T')[0].split('-')[2]}-${prod.enddate.split('T')[0].split('-')[1]}-${prod.enddate.split('T')[0].split('-')[0]}`
          )
        },
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 16, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 16, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 16, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Type Of Service', name: 'category', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Category', status: true, disabled: '', required: true,gride:4 },
        { title: 'Type Of Business', name: 'customercategory', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Customer Category', status: true, disabled: '', required: true,gride:4 },
        { title: 'Start Date', name: 'startdate', type: 'date', value: '', disabled: '', required: true,gride:4 },
        { title: 'End Date', name: 'enddate', type: 'date', value: '', disabled: '', required: true,gride:4 },
        { title: 'VAT', name: 'vat', type: 'text', value: '', disabled: '', required: true ,gride:4},
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true,gride:4 },
       
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Standing Charge',
      title: 'Standing Charge',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "from",
          text: "Category",
          sort: true,
          formatter: (cell, prod, inde) => (
            prod.category !== undefined && prod.category !== null ? prod.category.name :''
          )
        },
        {
          dataField: "from",
          text: "Supplier",
          sort: true,
          formatter: (cell, prod, inde) => (
            prod.supplier !== undefined && prod.supplier !== null ? prod.supplier.name :'No Supplier'
          )
        },
        {
          dataField: "from",
          text: "Region",
          sort: true,
          formatter: (cell, prod, inde) => (
            prod.supplierregion !== undefined && prod.supplierregion !== null ? prod.supplierregion.name :''
          )
        },
        {
          dataField: "from",
          text: "Meter Type",
          sort: true,
          formatter: (cell, prod, inde) => (
            prod.typeofmeter !== undefined && prod.typeofmeter !== null ? prod.typeofmeter.name :''
          )
        },
       
        {
          dataField: "from",
          text: "Start Date",
          sort: true,
          formatter: (cell, prod, inde) => (
            `${prod.startdate.split('T')[0].split('-')[2]}-${prod.startdate.split('T')[0].split('-')[1]}-${prod.startdate.split('T')[0].split('-')[0]}`
          )
        },
        
        {
          dataField: "to",
          text: "End Date",
          sort: true,
          formatter: (cell, prod, inde) => (
            `${prod.enddate.split('T')[0].split('-')[2]}-${prod.enddate.split('T')[0].split('-')[1]}-${prod.enddate.split('T')[0].split('-')[0]}`
          )
        },
        {
          dataField: "costperannum",
          text: "Cost Per Annual",
          sort: true,
        },
        {
          dataField: "perday",
          text: "Cost Per Day",
          sort: true,
        },
        
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 17, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 17, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 17, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Supplier List', name: 'supplier', type: 'select', value: [], outputData: getSupplier, outputDropDown: 'name', table: 'Company List', status: true, disabled: '', required: true,gride:3 },
        { title: 'Type Of Service', name: 'category', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Category', status: true, disabled: '', required: true,gride:3 },
        { title: 'Supplier Region', name: 'supplierregion', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Supply Region', status: true, disabled: '', required: true,gride:3 },
        { title: 'Type Of Meter', name: 'typeofmeter', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Type Of Meter', status: true, disabled: '', required: true,gride:3 },
        { title: 'Start Date', name: 'startdate', type: 'date', value: '', disabled: '', required: true,gride:3 },
        { title: 'End Date', name: 'enddate', type: 'date', value: '', disabled: '', required: true,gride:3 },
        { title: 'Cost Per Annum', name: 'costperannum', type: 'text', value: '', disabled: '', required: true,gride:3 },
        { title: 'Cost Per Dat', name: 'perday', type: 'text', value: '', disabled: true, required: '',gride:3 },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true ,gride:3},
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Carbon Tax',
      title: 'Carbon Tax',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
      
        {
          dataField: "from",
          text: "Type Of Service",
          sort: true,
          formatter: (cell, prod, inde) => (
            prod.category !== undefined && prod.category !== null ? prod.category.name :''
          )
        },
        {
          dataField: "from",
          text: "Type Of Business",
          sort: true,
          formatter: (cell, prod, inde) => (
            prod.customercategory !== undefined && prod.customercategory !== null ? prod.customercategory.name :''
          )
        },
        {
          dataField: "carbontax",
          text: "Carbon Tax",
          sort: true,
        
        },
        {
          dataField: "from",
          text: "Start Date",
          sort: true,
          formatter: (cell, prod, inde) => (
            `${prod.startdate.split('T')[0].split('-')[2]}-${prod.startdate.split('T')[0].split('-')[1]}-${prod.startdate.split('T')[0].split('-')[0]}`
          )
        },
        
        {
          dataField: "to",
          text: "End Date",
          sort: true,
          formatter: (cell, prod, inde) => (
            `${prod.enddate.split('T')[0].split('-')[2]}-${prod.enddate.split('T')[0].split('-')[1]}-${prod.enddate.split('T')[0].split('-')[0]}`
          )
        },
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 18, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 18, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 18, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Supplier Name', name: 'supplierlist', type: 'select', value: [], outputData: getSupplier, outputDropDown: 'name', table: 'Company List', status: true, disabled: '', required: true, },
        { title: 'Type Of Service', name: 'category', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Category', status: true, disabled: '', required: true,gride:4 },
        { title: 'Type Of Business', name: 'customercategory', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Customer Category', status: true, disabled: '', required: true,gride:4 },
        { title: 'Start Date', name: 'startdate', type: 'date', value: '', disabled: '', required: true,gride:4 },
        { title: 'End Date', name: 'enddate', type: 'date', value: '', disabled: '', required: true,gride:4 },
        { title: 'Carbon Tax', name: 'carbontax', type: 'text', value: '', disabled: '', required: true ,gride:4},
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true,gride:4 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
 
    {
      name: 'Billing Mode',
      title: 'Billing Mode',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
      
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 19, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 19, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 19, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Usage',
      title: 'Usage',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Type Of Meter",
          sort: true,
          formatter: (cell, prod, inde) => (
            prod.typeofmeter != undefined && prod.typeofmeter != null ?prod.typeofmeter.name :''
          )
        },
        {
          dataField: "name",
          text: "Region",
          sort: true,
          formatter: (cell, prod, inde) => (
            prod.region != undefined && prod.region != null ?prod.region.map(d=> `${d.name},`) :''
          )
        },
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 20, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 20, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 20, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Type Of Meter', name: 'typeofmeter', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Type Of Meter', status: true, disabled: '', required: true, },
        { title: 'Supply Region', name: 'region', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Supply Region', status: true, disabled: '', required: true, },
        { title: 'Annual Consumption', name: 'annualunit', type: 'text', value: '', disabled: '', required: true },
        { title: 'Day Consume(%)', name: 'dayconsumption', type: 'text', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Night Consume(%)', name: 'nightconsumption', type: 'text', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Peak Consume(%)', name: 'peakconsumption', type: 'text', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true },
        { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: true },
        { title: 'Consumption Type', name: 'consumptiontype', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'All Time / Day', name: 'consumptiontype', value: "All Day" }, { title: 'Night', name: 'consumptiontype', value: 'Night' },{ title: 'Peak', name: 'consumptiontype', value: 'Peak' }] },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
   
    {
      name: 'Type Of Consumption',
      title: 'Type Of Consumption',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        
        {
          dataField: "supplierlist",
          text: "Supplier Name",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) =>(
            prod.supplierlist != undefined && prod.supplierlist != null ? prod.supplierlist.name :'No Supplier'
          ),
        },
        {
          dataField: "typeofconsumption",
          text: "Type Of Consumption",
          sort: true,
       
        },
        {
          dataField: "coal",
          text: "Coal",
          sort: true,
       
        },
        {
          dataField: "gas",
          text: "Gas",
          sort: true,
       
        },
        {
          dataField: "oil",
          text: "Oil",
          sort: true,
       
        },
        {
          dataField: "renewable",
          text: "Renewable",
          sort: true,
       
        },
        {
          dataField: "other",
          text: "Other",
          sort: true,
       
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 21, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 21, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 21, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Supplier Name', name: 'supplierlist', type: 'select', value: [], outputData: getSupplier, outputDropDown: 'name', table: 'Company List', status: true, disabled: '', required: true, },
        { title: 'Type Of Consumption', name: 'typeofconsumption', type: 'text', value: '', disabled: '', required: true },
        { title: 'Coal', name: 'coal', type: 'text', value: '', disabled: '', required: true },
        { title: 'Gas', name: 'gas', type: 'text', value: '', disabled: '', required: true },
        { title: 'Oil', name: 'oil', type: 'text', value: '', disabled: '', required: true },
        { title: 'Renewable', name: 'renewable', type: 'text', value: '', disabled: '', required: true },
        { title: 'Other', name: 'other', type: 'text', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
   
  ])

  useEffect(async () => {
    const cardElements = [...cardElement]
    for (let i = 0; i < cardElements.length; i++) {
      await cardElements[i].getStatus(cardElements[i].name).then(result=>{
        console.log(result)
       if(result.success){
        cardElements[i].total = result.total;
        cardElements[i].active = result.active;
        cardElements[i].inactive = result.inactive;
        cardElements[i].isLoading=false
       }else{
        cardElements[i].total = 0;
        cardElements[i].active = 0;
        cardElements[i].inactive = 0;
        cardElements[i].isLoading=false
       }
      })
      
          setCardElement([...cardElements])
    }
    // setIsLoading(false)
  }, [])
  const changeTable = async (e, type, index, prod) => {
   
    setIsLoading(true)
    const cardElements = [...cardElement]
    setIndexValue(index)
    setTitle(cardElements[index].title)
    if(type == true || type == false){
      setOperationTable('Table')
    }else{
      setOperationTable(type)
    }

  if (type == 'Table' || type == true || type == false) {

      setColumns(cardElements[index].HeaderColumns)
      setSingleItem({ ...singleItem })
      setStatus(type == 'Table'? 'All':type)
      await getMiscellaneous(cardElements[index].name,type == 'Table'? 'All':type).then(result => {
        console.log(result)
        if (result.success) {
          setData(result.data)
          setIsLoading(false)
        } else {
          setData([])
          setIsLoading(false)
        }
      })
 
    } else if (type == 'View' || type == 'Deleted' || type == 'Edit') {
     await getMiscellaneous(cardElements[index].name, prod._id).then(async result => {

        if (result.success) {
          for (let j = 0; j < cardElements[index].inputType.length; j++) {
            if(cardElements[index].inputType[j].inputtype  && cardElements[index].inputType[j].inputtype != undefined && cardElements[index].inputType[j].inputtype  != null ){
              cardElements[index].inputType[j].type = cardElements[index].inputType[j].inputvalue 
            }
            if (cardElements[index].inputType[j].type === 'multiple' || cardElements[index].inputType[j].type === 'select') {
              cardElements[index].inputType[j].value =[]
              await cardElements[index].inputType[j].outputData(cardElements[index].inputType[j].table, cardElements[index].inputType[j].status).then(results => {
                for (let k = 0; k < results.data.length; k++) {
                  Object.keys(results.data[k]).filter(q => q === 'subject')
    
                  let data = {
                    label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElements[index].inputType[j].outputDropDown)],
                    value: results.data[k]._id,
                  }
                  cardElements[index].inputType[j].value.push(data)
                }
              })
              setInputType([...cardElements[index].inputType])
              setSingleItem({ ...result.data })
            
              // setIsLoading(false)
            }else{
              setInputType([...cardElements[index].inputType])
              setSingleItem({ ...result.data }) 
           
              // setIsLoading(false)
            }
          }
          setInputType([...cardElements[index].inputType])
          setSingleItem({ ...result.data })
     
          setIsLoading(false)
        } else {
          setSingleItem({})
          setIsLoading(false)
        }

      })

    
    } else if (type == 'Add') {
      console.log(cardElements[index].inputType.length)
      for(let j = 0; j < cardElements[index].inputType.length; j++) {

        if (cardElements[index].inputType[j].type === 'multiple' || cardElements[index].inputType[j].type === 'select') {
          cardElements[index].inputType[j].value = []
          await cardElements[index].inputType[j].outputData(cardElements[index].inputType[j].table, cardElements[index].inputType[j].status).then(results => {
            for (let k = 0; k < results.data.length; k++) {
              Object.keys(results.data[k]).filter(q => q === 'subject')

              let data = {
                label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElements[index].inputType[j].outputDropDown)],
                value: results.data[k]._id,
              }
              cardElements[index].inputType[j].value.push(data)
            }
          })
        }
        console.log('lll')
      }
      setInputType([...cardElements[index].inputType])
     
      setSingleItem({status:true,currentyear:false})
      setIsLoading(false)
          }
  }


  const postData = (e, values) => {

    const cardElements = [...cardElement]

    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    if(values.name !== undefined ){
      values['slug'] = values.name.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name
  }
  setSaveData(true)
    postMiscellaneous(isEmpty(values), cardElements[indexValue].name, operationsTable).then(result => {
      if (result.success) {
        setIsLoading(true)
        setColumns([...cardElements[indexValue].HeaderColumns])
      
        getMiscellaneous(cardElements[indexValue].name, status).then(result => {
          if (result.success) {
            setData(result.data)
            setOperationTable('Table')
            setIsLoading(false)
          } else {
            setData([])
            setOperationTable('Table')
            setIsLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  const openUrlLink = (d, k) => {
    window.open(
      `${url.commonUrls}/image/${d}/${k}`,
      '_blank' // <- This is what makes it open in a new window.
    );

  }
  const mainPageContent=async(e,prod,index,typeData)=>{
    setIsLoading(true)
    setIndexValue(index)
    await getPageContent(prod._id,'Category').then((result)=>{
      console.log(result)
      if(result.success){
        setData(result.data)
        setSingleItem(result.page)
        setOperationTable(typeData)
        setIsLoading(false)
      }else{
        setData([])
        setSingleItem({})
        setOperationTable(typeData)
        setIsLoading(false)
      }
    })
  }
  const submitFormData=async(e,data,ind)=>{
    e.preventDefault()
    console.log(data)
    setSaveData(true)
    const cardElementList = [...cardElement]
    console.log(ind)
    await postPageContent(data,'Category').then(result=>{
      if(result.success){
        alert("Update Data Successfully")
        setSaveData(false)
        
        window.location.href='/miscellaneous'
      }
     
    })
  }
  const selctdropDownList=async (e, rowData,title)=>{
    setSearchFilter(e)
    const cardElements = [...cardElement]
    setMessagesLoading(true)
    setMessages(`${e.label} Data Loading ...`)    
  await postMiscellaneous(isEmpty({datalist:rowData}), title, e.label).then(result => {
    console.log(result)
    if(result.success) {
      setMessages(`${e.label} Data Successfully ...`)   
      if(e.label == "Export" || e.label == "Sample"){
        window.location.href=`${url.commonUrls}/download/${result.data.fileName}`
      }else{
         setIsLoading(true) 

         setColumns([...cardElements.find(d => d.name === title).HeaderColumns])
          getMiscellaneous(title, status).then(result => {
            if (result.success) {
              setSearchFilter({})
              setData(result.data)
              setOperationTable('Table')
              setIsLoading(false)
            } else {
              setData([])
              setOperationTable('Table')
              setIsLoading(false)
            }
  
          })
       }
      } else {
        alert('Hello')
      }
    })
   
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Miscellaneous | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == '' ?
              <Breadcrumbs
                title={"Dashboard"}
                breadcrumbItem={"Miscellaneous"}
                bredcumlink={"dashboard"}
              />
              : <Breadcrumbs
                title={'Miscellaneous'}
                breadcrumbItem={title}
                bredcumlink={'miscellaneous'}
              />
          }

          <Row>
            {
              
              operationsTable == '' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}>
                    {
                     <GalleryCardElementwithEvent
                      data={d}
                      changeTable={(e, type) => changeTable(e, type, index)}
                  
                    />
                
                    }
                       

                  </Col>
                ))
                :
                operationsTable == 'Main Page Content' ?
                  
                isLoading ?
                 <UiLoading/>
              :
              <CustomeTab  data={data} singleItem={singleItem}  saveData={saveData} submitFormData={(e)=>submitFormData(e,data,indexValue)}/>
              
              : 
                operationsTable == 'Table' ?
                <Card>
                    {
                       messageLoading ?  
                        <p style={{textAlign: "center",color: 'green',paddingTop: '16px'}}>{messages}</p>
                       :''
                      }
                       
                        <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type,) => changeTable(e, type, indexValue)}
                          columns={columns}
                          selctdropDownList={(e,rowData)=>selctdropDownList(e,rowData,indexValue)}
                          searchFilter={searchFilter}
                          isLoading={isLoading}
                          // searchType={searchType}
                          // message={ Object.keys(searchFilter).length != 0? `${searchFilter.label} Data Operation ...` :{}}
                        />
                      
                    

                  </Card>
                  :
                  operationsTable == 'Add' || operationsTable == 'Edit' || operationsTable == 'Deleted' || operationsTable == 'View' ?
                    <>
                      {
                        isLoading ?
                          <UiLoading />
                          :
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'miscellaneous'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type) => changeTable(e, type, indexValue)}
                            inputType={inputType}
                            postData={postData}
                         
                          />
                      }
                    </>
                    : ''
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default withTranslation()(Miscellaneous)
